<template>
  <v-app class="transparent">
    <v-container id="regular-tables" fluid tag="section">
      <base-v-component heading="Prescription List" />

      <v-card icon="mdi-domain" title="Prescription List" class="px-5 py-3" :loading="loading">
        <v-simple-table>
          <filter-list :filters="filter" :on-change="fetchList" />

          <thead>
          <tr>
            <th @click="setSort('id')">ID <span v-if="query.sort && query.sort.includes('id')">{{ sortIcon('id') }}</span></th>
            <th @click="setSort('partner_user_id')">Assignee ID <span v-if="query.sort && query.sort.includes('partner_user_id')">{{ sortIcon('partner_user_id') }}</span></th>
            <th @click="setSort('consultation_id')">Consultation ID <span v-if="query.sort && query.sort.includes('consultation_id')">{{ sortIcon('consultation_id') }}</span></th>
            <th>Delivery Status</th>
            <th @click="setSort('transferred_at')">Transferred At <span v-if="query.sort && query.sort.includes('transferred_at')">{{ sortIcon('transferred_at') }}</span></th>
            <th @click="setSort('phr_name')">PHR Name <span v-if="query.sort && query.sort.includes('phr_name')">{{ sortIcon('phr_name') }}</span></th>
            <th @click="setSort('policy_number')">Policy Number <span v-if="query.sort && query.sort.includes('policy_number')">{{ sortIcon('policy_number') }}</span></th>
            <th @click="setSort('phr_phone')">PHR Phone <span v-if="query.sort && query.sort.includes('phr_phone')">{{ sortIcon('phr_phone') }}</span></th>
            <th @click="setSort('tw_user_phone')">TW User Phone <span v-if="query.sort && query.sort.includes('tw_user_phone')">{{ sortIcon('tw_user_phone') }}</span></th>
            <th @click="setSort('national_id')">National ID <span v-if="query.sort && query.sort.includes('national_id')">{{ sortIcon('national_id') }}</span></th>
            <th @click="setSort('city')">City <span v-if="query.sort && query.sort.includes('city')">{{ sortIcon('city') }}</span></th>
            <th v-if="canModify" class="text-center">Actions</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="prescription in prescriptions" :key="prescription.id">
            <td>{{ getSafe(() => prescription.id) }}</td>
            <td>
              <span>{{ getSafe(() => prescription.partner_user_id ? prescription.partner_user_id : 'N/A') }}</span>
              <span class="red--text font-weight-bold" v-if="$auth.getCurrentUser().id === prescription.partner_user_id"> - Assigned to Me</span>
            </td>
            <td>{{ getSafe(() => prescription.consultation_id) }}</td>
            <td>{{ getSafe(() => prescription.delivery_status.name) }}</td>
            <td>{{ getSafe(() => prescription.transferred_at) }}</td>
            <td>{{ getSafe(() => prescription.phr.name) }}</td>
            <td>{{ getSafe(() => prescription.tw_user.policy_number) }}</td>
            <td>{{ getSafe(() => prescription.phr.phone) }}</td>
            <td>{{ getSafe(() => prescription.tw_user.phone) }}</td>
            <td>{{ getSafe(() => prescription.phr.national_no) }}</td>
            <td>{{ getSafe(() => prescription.phr.member.city.name) }}</td>
            <td v-if="canModify" class="text-center">
              <div class="d-inline-flex">
                <v-btn x-small fab color="green" class="m-2 ma-2" :to="{ name: 'prescriptions.update', params: {id:prescription.id}}">
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>

                <v-btn x-small fab color="green" class="mr-2 ma-2" :to="{ name: 'prescriptions.show', params: {id:prescription.id}}">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                <v-btn :disabled="prescription.partner_user_id && !($auth.isAdmin() || $auth.isSuperPharmacist())" x-small fab color="green" class="mr-2 ma-2" @click="showAssignForm(prescription.id)">
                  <v-icon>mdi-clipboard-account</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <div class="text-center">
        <v-pagination
          v-if="pagination.total > 10"
          v-model="query.page"
          :length="pagination.length"
          :total-visible="7"
          @input="fetchList"
          @next="fetchList"
          @previous="fetchList"
        />
      </div>
      <AssignForm ref="assignForm" @submitted="fetchList"></AssignForm>
    </v-container>
  </v-app>
</template>

<script>
import FilterList from "../../components/base/FilterList";
import AssignForm from "./AssignForm.vue";
export default {
  name: "PrescriptionList",
  components: {FilterList, AssignForm},
  data() {
    return {
      prescriptions: [],
      pagination: {
        page: 1,
        length: 0,
        total: 0
      },
      query: {
        page: 1,
        sort: ""
      },
      filter: [
        {name: "page", value: 1, type: "pagination"},
        {name: "id", value: "", type: "text_field"},
        {name: "partner_user_id", value: "", type: "text_field"},
        {name: "consultation_id", value: "", type: "text_field"},
        {name: "delivery_status", value: "", type: "text_field"},
        {name: "transferred_at", value: "", type: "text_field"},
        {name: "phr_name", value: "", type: "text_field"},
        {name: "policy_number", value: "", type: "text_field"},
        {name: "phr_phone", value: "", type: "text_field"},
        {name: "tw_user_phone", value: "", type: "text_field"},
        {name: "national_id", value: "", type: "text_field"},
        {name: "city", value: "", type: "text_field"},
        {name: "options", value: "", type: "options"},
      ],
      loading: false
    };
  },
  computed: {
    canModify() {
      return this.$auth.isAdmin() || this.$auth.isSuperPharmacist() || this.$auth.isPharmacist();
    }
  },
  watch: {
    $route() {
      this.getUrlParams();
      this.fetchList();
    }
  },
  created() {
    this.getUrlParams();
    this.fetchList();
  },
  methods: {
    showAssignForm(id) {
      this.$refs.assignForm.showAssignForm(id);
    },
    async fetchList() {
      try {
        this.loading = true;

        const {data: {data, last_page, total}} = await this.$authApi.get("/prescriptions", {
          params: this.query
        });

        this.prescriptions = data;
        this.pagination.length = parseInt(last_page);
        this.pagination.total = parseInt(total);
      } catch (e) {
        console.error("Error fetching prescriptions:", e);
      } finally {
        this.loading = false;
      }
    },
    getUrlParams() {
      if (!Object.keys(this.$route.query).length) {
        this.query = {page: 1, sort: ""};
        this.$root.$emit("refreshParams", this.filter);
      } else {
        this.query = this.$route.query;
        if ("page" in this.query && this.query.page) {
          this.query.page = parseInt(this.query.page);
        }
        this.filter.forEach(param => {
          param.value = this.query[param.name];
        });
      }
    },
    getSafe(fn) {
      try {
        return fn();
      } catch (e) {
        return "";
      }
    },
    setSort(column) {
      if (this.query.sort === column) {
        this.query.sort = `-${column}`;
      } else if (this.query.sort === `-${column}`) {
        this.query.sort = "";
      } else {
        this.query.sort = column;
      }
      this.fetchList();
    },
    sortIcon(column) {
      return this.query.sort === column ? "↑" : this.query.sort === `-${column}` ? "↓" : "";
    },
  }
};
</script>
